import React, { useState, useEffect } from "react";
import { Button ,Container} from "react-bootstrap";
import axios from "axios";
import Table from "react-bootstrap/Table";
import { Col, Row, Divider, DatePicker, Select, Space } from "antd";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import * as Icon from "react-bootstrap-icons";
import { Link } from "react-router-dom";

export default function Withdrawhistory() {
  const [users, setUsers] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [date_time, setDate] = useState("");
  const [loading1, setLoading] = useState(false);
  const [jsonData, setJsonData] = useState(undefined);
  const [loading2, setLoading2] = useState(false);
  const [dateRange, setDateRange] = useState([]);

  const handleReload = () => {
    window.scrollTo(0, 0);
    window.location.reload();
  };
  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
    loaduser("", dates);
  };

  const loaduser = async (dateRange) => {
    // setActiveButton(dateRange);
    console.log("Fetching data with date range:", dateRange);
    let flt_date, end;

    if (Array.isArray(dateRange) && dateRange.length === 2) {
      flt_date = dateRange[0].toISOString().slice(0, 10);
    } else {
      flt_date = new Date().toISOString().slice(0, 10);
    }
    if (jsonData) {
      // Parse the JSON data here
      const parsedData = JSON.parse(jsonData);
      // ... rest of your code
    } else {
      // Handle the case where jsonData is undefined or null
      // For example, set a default value or show an error message.
    }
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    setLoading(true);
    //  alert(dev_id)

    // alert(dev_id);
    let url = `${process.env.REACT_APP_API_URL}/wallet_history.php`;
    //  console.warn(url);
    const formData = new FormData();
    formData.append("app_id", process.env.REACT_APP_API_ID);
    formData.append("user_id", user_id);
    // alert(user_id)
    // formData.append('market_id', gameid);
    formData.append("dev_id", dev_id);
    formData.append("type", "2");
    formData.append("flt_date", date_time);
    // formData.append('market_name', market_name);

    // formData.append('market_name', market_name);

    var config = {
      method: "POST",
      url: url,
      body: formData,
    };
    axios
      .post(url, formData, config)
      .then(function (response) {
        const res = JSON.stringify(response.data.data);
        //  alert(res);
        if (res != undefined) {
          const objectRes = JSON.parse(res);
          setUsers(objectRes);
          console.warn(objectRes);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loaduser();
  }, []);

  return (
    <>
    <section className="margin-bottom-88" id='history'>
                <div className="container-fluid paddingall-5">
                    <div className='historybet marketdetails'>
                        <Divider orientation="center">withdraw History</Divider>

                        <Row gutter={16}>


                            {/* 
                            <Col span={4}>
                                <div className="reloadhistory" onClick={handleReload}>
                                    <i class="bi bi-arrow-clockwise"></i>
                                </div>
                            </Col> */}
                        </Row>
                    </div>
                    <div className="table-responsive">

                        
                    <div className="table  table-history">
                            <Table striped bordered hover className=''>
                                <thead>
                                    <tr>
                                        {/* <th>S.NO</th> */}
                                        <th>Sr no.</th>
                                        <th>Amount</th>
                                        <th>txn iD</th>
                                        <th>status </th>
                                        <th>Date </th>
                                    </tr>
                                </thead>
                                {/* {user.is_result_declared} */}
                                {loading1 ? (
                                    <div className="spinner-wrapper">
                                        <div className="loadernew2"></div>
                                    </div>
                                ) : (
                                    <tbody className='bgbody'>
                                     {users.filter(user => user.tr_nature === "TRWITH003").map((user, index) => (
                                                <React.Fragment key={index}>
                                              
                                                        <tr>
                                                            <td>{index + 0}</td>
                                                            {/* <td>{user.date_time}</td> */}
                                                            <td>{user.tr_value}</td>
                                                            <td>{user.transaction_id}</td>
                                                            {/* <td>{user.tr_status}</td> */}
                                                            <td className='text-success'>
                                                                <span style={{ color: user.tr_status === 'Success' ? 'green' : 'red' }}>
                                                                    {user.tr_status}
                                                                </span>
                                                            </td>
                                                            <td>{user.created_at}</td>
                                                        </tr>
                                           
                                                </React.Fragment>
                                            ))}
                                            <>
                                            {users.filter(user => user.tr_nature === "TRWITH003").length === 0 && (
                                            <tr>
                                                <td colSpan="7">No data available or something went wrong.</td>
                                            </tr>
                                        )}
                                        </>
                                    </tbody>
                                )}
                            </Table>
                        </div>

                    </div>



                </div>



            </section >
      
    </>
  );
}
