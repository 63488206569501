import React, { useState, useEffect } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import Playgame from "./Playgame";
import { Link } from "react-router-dom";
import axios from "axios";
import { Carousel } from "antd";
import logo from "../../assets/img/logo.png";
import play from "../../assets/img/check-ch.png";
import call_bg from "../../assets/img/call_bg.png";
import whatsapp_bg from "../../assets/img/whatsappicon.png";

import playoff from "../../assets/img/playredbtn.png";
import playon from "../../assets/img/playon.png";
import callicon from "../../assets/img/callicon.png";
import chart from "../../assets/img/chart.jpg";
// import call_bg from "../../assets/img/call_bg.png";
import { FaPlay } from "react-icons/fa";
import { VscDebugStart } from "react-icons/vsc";
import { FcStart } from "react-icons/fc";
import { RiBarChartGroupedLine } from "react-icons/ri";
import { FaArrowRight, FaTelegram } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { RiMoneyDollarCircleFill,RiWallet3Fill  } from "react-icons/ri";
import { MdOutlineStars } from "react-icons/md";

import {
  StarFilled,
  FileTextOutlined,
  PlayCircleFilled,
} from "@ant-design/icons";

const phoneNumber = "9079350278"; // Replace with your WhatsApp business number
const message = "Hello! I need assistance."; // Replace with your default message

const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
  message
)}`;
const phoneNumbertl = "9079350278"; // Replace with your WhatsApp business number
const messagetl = "Hello! I need assistance."; // Replace with your default message

const TlLink = `tg://msg?phone=${phoneNumbertl}&text=${encodeURIComponent(
  messagetl
)}`;
export default function Play() {
  const [users, setUsers] = useState([]);
  const [sliders, setUserslider] = useState([]);
  const [Slider_url, setSlider_url] = useState([]);
  const [usersdata, setUsersdata] = useState([]);
  const [loading1, setLoading] = useState(false);
  const navigate = useNavigate();
  const [profiles, setProfiles] = useState([]);

  const handleReload = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    loaduser();
    slider();
    loaduser1();
  }, []);

  const loaduser = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    // alert(dev_id)
    setLoading(true);

    // alert(user_id);
    let url = `${process.env.REACT_APP_API_URL}/kalyan_market.php`;
    // console.warn(url);
    const formData = new FormData();
    formData.append("app_id", process.env.REACT_APP_API_ID);
    formData.append("user_id", user_id);
    formData.append("dev_id", dev_id);
    // alert(dev_id)
    var config = {
      method: "POST",
      url: url,
      body: formData,
    };
    axios
      .post(url, formData, config)
      .then(function (response) {
        const res = JSON.stringify(response.data.data);
        const objectRes = JSON.parse(res);
        setUsers(objectRes);
        console.warn(objectRes);
      })
      .finally(() => {
        // setTimeout(() => {
        setLoading(false);
        // }, 1000);
      });
  };
  const slider = async () => {
    let url = `${process.env.REACT_APP_API_URL}/slider.php`;

    const formData = new FormData();
    // alert(dev_id)
    var config = {
      method: "POST",
      url: url,
      body: formData,
    };
    axios.post(url, formData, config).then(function (response) {
      const res = JSON.stringify(response.data.data);
      const baseurl = response.data.Slider_url;
      setSlider_url(baseurl);
      console.warn(baseurl);
      const objectRes = JSON.parse(res);
      setUserslider(objectRes);
      console.warn(objectRes);
    });
  };

  // const handleClick = (id) => {
  //   window.location.href = `/Pana?id=${id}`;
  // };
  const handleClick = (id, name) => {
    localStorage.setItem("market_id", id);
    // localStorage.setItem('market_name',name);
    // window.location.href = `/Jodi-Game`;
    navigate("/Pana");
  };
  const handleClick1 = async (id) => {
    navigate('/Resulthistory');
    localStorage.setItem('marketchart_id', id,);

  };
  const handleClickclose = (id, name) => {
    if ("vibrate" in navigator) {
      // vibration API supported
      navigator.vibrate(50);
    }
  };





  const loaduser1 = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    let url = `${process.env.REACT_APP_API_URL}/get_user_profile.php`;
    const formData = new FormData();
    formData.append("app_id", process.env.REACT_APP_API_ID);
    formData.append("user_id", user_id);
    formData.append("dev_id", dev_id);

    var config = {
      method: "POST",
      url: url,
      data: formData, // Use 'data' instead of 'body'
    };

    axios(config)
      .then(function (response) {
        console.log("API Response:", response);
        const res = response.data;
        if (res !== undefined) {
          // alert(res);
          setProfiles(res);
          console.warn(res);
        }
      })
      .catch(function (error) {
        console.error("Error fetching profile data: ", error);
      });
  };
  // const handleClick = (id) => {
  return (
    <>
      {profiles && profiles.is_play_store == 1 ?



      <section id="play" className="margin-bottom-88 mb-0">
        <div className="playsection">
          <div className="playsection_fixed">
            <div className="fixed_carousel">
             
      

              <Container fluid>
                <div className="header-top-bottm">
               
                </div>
              </Container>
              <div class='px-1'>
               
              </div>
            </div>
          </div>

          <div className="height_65">
            <div className=" border-radius-8">
              <div className="playg">
                {users &&
                  users.map((user) => (
                    <>
                      <div className="new">
                        {user.is_play == 1 ? (
                          <div className="d-flex align-items-start justify-content-between padding-5">
                            <div className="div text-center w-20">
                              <div className="chart_image_new" onClick={() => {
                                handleClick1(user.id);
                              }}>
                                <img className="croseimg" src={chart} alt="" />
                              </div>
                              <span>&nbsp; </span>

                              {/* <span className="text-bold">Chart</span> */}
                            </div>
                            <div className="div  w-80">
                              <div className="marketname text-center">
                                <h4 className="animate-charcter flex-wrap marketnamenew mb-0 d-flex justify-content-center market_name text-center">
                                  {user.name}
                                </h4>
                                {/* <small className="p-0">[***-**-***]</small> */}
                                <small className="p-0">   {user.marketResult1 == null ? '***' : user.marketResult1} - {user.marketResult2 == null ? '**' : user.marketResult2} - {user.marketResult3 == null ? '***' : user.marketResult3}</small>

                                {/* <p
                                  onClick={() => {
                                    handleReload();
                                  }}
                                  className="playgames p-0 "
                                >
                                  Market opened{" "}
                                </p> */}

                                {/* <div className="marketnamepaly">
                                  <div className="date_time">
                                    <p className="animate-charcter">
                                      <span className="openclose">Open:   </span>{" "}
                                      {user.Opentime}
                                    </p>
                                    ||
                                    <p className="animate-charcter">
                                      Closed: {user.Closetime}
                                    </p>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                            {/* <div onClick={() => { handleClick(user.id); }} className="div text-dark w-20">
                  <span><img className="playimg" src={play} alt="" /></span>
                  <p className="openn">Open</p>
                </div> */}
                            <div
                              // onClick={() => {
                              //   handleClick(user.id);
                              // }}
                              className="div  text-center game_section_time w-20"
                            >
                              <div className="game_off">
                                <img className="croseimg" src={playon} alt="" />
                              </div>
                              <p className="game_type">running </p>
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex align-items-center justify-content-between padding-5" 
                          >
                            <div className="div text-center w-20">
                              <div className="chart_image_new" onClick={() => {
                                handleClick1(user.id);
                              }}>
                                <img className="croseimg" src={chart} alt="" onClick={handleClickclose}/>
                              </div>
                              {/* <span>&nbsp; </span> */}
                              {/* <span>Chart</span> */}
                            </div>
                            <div className="div  w-80">
                              <div className="marketname text-center">
                                <h4 className="animate-charcter flex-wrap marketnamenew mb-0 d-flex justify-content-center market_name text-center">
                                  {user.name}
                                </h4>
                                <small className="p-0">   {user.marketResult1 == null ? '***' : user.marketResult1} - {user.marketResult2 == null ? '**' : user.marketResult2} - {user.marketResult3 == null ? '***' : user.marketResult3}</small>
                                {/* <small className="p-0">[***-**-***]</small> */}

                                {/* <p
                                  onClick={() => {
                                    handleReload();
                                  }}
                                  className="playgames1 p-0 "
                                >
                                  Market Closed{" "}
                                </p> */}
                                {/* <div className="marketnamepaly ">
                                  <div className="date_time ">
                                    <p className="animate-charcter">
                                      Open: {user.Opentime}
                                    </p>
                                    |
                                    <p className="animate-charcter">
                                      Close: {user.Closetime}
                                    </p>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                            <div className="div game_section_time text-center w-20">
                              <div className="game_off">
                                <img
                                  className="croseimg"
                                  src={playoff}
                                  onClick={handleClickclose}
                                  alt=""
                                />
                              </div>
                              <p className="game_type close_color">closed    </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>
        {loading1 && (
          // <>
          // <div className="loader_roomcode">
          //   <img src={loader} alt="loader" />
          // </div>
          // </>
          <div className="spinner-wrapper">
            <div className="loadernew2"></div>
          </div>
        )}
      </section>
      : ""}





















      



   {profiles && profiles.is_play_store == 0 ?

<section id="play" className="playhome margin-bottom-88 mb-0">
  <div className="playsection">
    <div className="playsection_fixed">
      <div className="fixed_carousel">
        <div className="heightcarosuksel">
          <Carousel autoplay dots={false}>
            {sliders &&
              sliders.map((user) => (
                <div>
                  <img
                    // src={Slider_url/sliders}
                    src={`${Slider_url}/${user.images}`}
                    alt="banner"
                    className="img-fluid slider"
          
                  />
                </div>
              ))}
          </Carousel>
        </div>
        <div className="bg-color-custum ">
          <Container>
            <Row>
              <Col md={4} xs={4}>
                <div className="image_tab_div position-relative">
                <Link to="/Wallet">
                    <div className="image_tab text-center">
                      <RiWallet3Fill />
                      <h4 className="">Wallet  </h4>
                    </div>
                  </Link>
                </div>
              </Col>
              <Col md={4} xs={4}>
                <div className="image_tab_div position-relative">
                <Link to="/AddPayment ">
                    <div className="image_tab text-center">
                      <div className="icon_plus">+</div>
                      <h4 className="">Add Points</h4>
                    </div>
                  </Link>
                </div>
              </Col>
              <Col md={4} xs={4}>
                <div className="image_tab_div position-relative">
                <Link to="/WithdrawAmount" >
                    <div className="image_tab text-center">
                      <RiMoneyDollarCircleFill />

                      <h4 className="">Withdraw</h4>
                    </div>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <Container fluid>
          <div className="header-top-bottm">
            <Row className="buttons gy-2 mx-0">
              <Col xs={6} className="d-flex justify-content-center">
                {/* <div className="support btn imageoffcall" > */}
                {/* Your content */}
                <a
                  href="tel:+91-9694700216"
                  className="link_contact img_width_icond-flex jusitfy-content-start"
                >
                  <div className="image_icon_social d-flex justify-content-center">
                    <img src={whatsapp_bg} alt="call" width="25px" />
                    <span>9694700216</span>
                  </div>
                </a>
                {/* </div> */}
              </Col>

              <Col xs={6} className="d-flex justify-content-center">
                <a
              href="tel:+91-9694700216" 
                  target="_blank"
                  className="link_contact d-flex jusitfy-content-start img_width_icon"
                  rel="noopener noreferrer"
                >
                  {/* <div
                  className="support btn whatsappimagefor "

                  > */}
                  <div className="image_icon_social d-flex justify-content-center">
                    <img src={callicon} alt="whatsapp" />
                    <span>9694700216</span>
                  </div>
                  {/* </div> */}
                </a>
              </Col>
            </Row>
          </div>
        </Container>
        <div class='px-1'>
          <Row>
            <Col md={12} xs={12}>
            <Link to="/Disawar-Market " >
              <div className="d-flex justify-content-between market_name_details align-items-center">
                <div className="marketname_header">
                  <h5 className="mb-0">
                    GALI DISAWAR GAMES
                  </h5>
                </div>
                <div className="marketname_header d-flex align-items-center">
                  <h5 className="mb-0">Play</h5>
                  <MdOutlineStars />
                </div>
              </div>
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    </div>

    <div className="height_65 playhome">
      <div className=" border-radius-8">
        <div className="playg">
          {users &&
            users.map((user) => (
              <>
                <div className="new">
                  {user.is_play == 1 ? (
                    <div className="d-flex align-items-start justify-content-between padding-5">
                      <div className="div text-center w-20">
                        <div className="chart_image_new" onClick={() => {
                          handleClick1(user.id);
                        }}>
                          <img className="croseimg" src={chart} alt="" />
                        </div>
                        <span>&nbsp; </span>

                      </div>
                      <div className="div  w-80">
                        <div className="marketname text-center">
                          <h4 className="animate-charcter flex-wrap marketnamenew mb-0 d-flex justify-content-center market_name text-center">
                            {user.name}
                          </h4>
                          <small className="p-0">   {user.marketResult1 == null ? '***' : user.marketResult1} - {user.marketResult2 == null ? '**' : user.marketResult2} - {user.marketResult3 == null ? '***' : user.marketResult3}</small>

                    

                          <div className="marketnamepaly">
                            <div className="date_time">
                              <p className="animate-charcter">
                                <span className="openclose">Open: </span>{" "}
                                {user.Opentime}
                              </p>
                              ||
                              <p className="animate-charcter">
                                Closed: {user.Closetime}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                 
                      <div
                        onClick={() => {
                          handleClick(user.id);
                        }}
                        className="div  text-center game_section_time w-20"
                      >
                        <div className="game_off">
                          <img className="croseimg" src={playon} alt="" />
                        </div>
                        <p className="game_type">running </p>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center justify-content-between padding-5" 
                    >
                      <div className="div text-center w-20">
                        <div className="chart_image_new" onClick={() => {
                          handleClick1(user.id);
                        }}>
                          <img className="croseimg" src={chart} alt="" onClick={handleClickclose}/>
                        </div>
                        {/* <span>&nbsp; </span> */}
                        {/* <span>Chart</span> */}
                      </div>
                      <div className="div  w-80">
                        <div className="marketname text-center">
                          <h4 className="animate-charcter flex-wrap marketnamenew mb-0 d-flex justify-content-center market_name text-center">
                            {user.name}
                          </h4>
                          <small className="p-0">   {user.marketResult1 == null ? '***' : user.marketResult1} - {user.marketResult2 == null ? '**' : user.marketResult2} - {user.marketResult3 == null ? '***' : user.marketResult3}</small>
                          {/* <small className="p-0">[***-**-***]</small> */}

                          {/* <p
                            onClick={() => {
                              handleReload();
                            }}
                            className="playgames1 p-0 "
                          >
                            Market Closed{" "}
                          </p> */}
                          <div className="marketnamepaly ">
                            <div className="date_time ">
                              <p className="animate-charcter">
                                Open: {user.Opentime}
                              </p>
                              |
                              <p className="animate-charcter">
                                Close: {user.Closetime}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="div game_section_time text-center w-20">
                        <div className="game_off">
                          <img
                            className="croseimg"
                            src={playoff}
                            onClick={handleClickclose}
                            alt=""
                          />
                        </div>
                        <p className="game_type close_color">closed </p>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ))}
        </div>
      </div>
    </div>
  </div>
  {loading1 && (
    // <>
    // <div className="loader_roomcode">
    //   <img src={loader} alt="loader" />
    // </div>
    // </>
    <div className="spinner-wrapper">
      <div className="loadernew2"></div>
    </div>
  )}
</section>
: ""}

    </>
  );
}
