import React, { useState, useEffect, useRef  } from 'react';
import { Container, Col, Row, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import axios from 'axios';
import { FaArrowLeft } from "react-icons/fa";
import { Link ,useLocation} from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import headerwallet from '../../assets/img/headerwallet.png';
export default function Headercommon(props) {
  const [users, setUsers] = useState([]);
  const [gameid, setGameid] = useState(localStorage.getItem('gameid') || '');
  const [path, setPath] = useState([]);
  const location = useLocation();
  const isPlayPage = location.pathname === '/Play';
  const navigate = useNavigate();
  const navigation = useRef(null);
  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    const updatePath = () => {
      const segment = window.location.pathname.split("/");
      setPath(segment);

    };
    updatePath();
    const intervaldId = setInterval(updatePath, 1000);
    return () => {
      clearInterval(intervaldId);
    }

  }, []);
  useEffect(() => {
    loaduser();
  }, []);

  const loaduser = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    let url = `${process.env.REACT_APP_API_URL}/get_user_profile.php`;
    const formData = new FormData();
    formData.append("app_id", process.env.REACT_APP_API_ID);
    formData.append("user_id", user_id);
    formData.append("dev_id", dev_id);

    var config = {
      method: "POST",
      url: url,
      data: formData, // Use 'data' instead of 'body'
    };

    axios(config)
      .then(function (response) {
        console.log("API Response:", response);
        const res = response.data;
        if (res !== undefined) {
          // alert(res);
          setProfiles(res);
          console.warn(res);
        }
      })
      .catch(function (error) {
        console.error("Error fetching profile data: ", error);
      });
  };

  const handleClick = () => {
    
    navigate(-1);
  };

 
  return (
    <>
      <div className="header-top">
        <Container>
          <Row className="d-flex align-items-center justify-content-between">
            <Col xs={2} md={2}>
           
             
                    <a onClick={handleClick} class="arrowlink text-light">                    
                    <FaArrowLeft />
                  </a>
            
            </Col>
            <Col xs={7} md={7}>
              <div className="menuicon d-flex justify-content-center">
                <div className="d-flex text-light">
                  <div class="headericonarrow header_common_tittle">
                  {path.join('')}
                  </div>
                </div>

              </div>
            </Col>
    
            <Col xs={3} md={3} className='p-0'>

            {profiles && profiles.is_play_store == 1 ?
                ""
                :
                ""
              }
               {profiles && profiles.is_play_store == 0 ? 
              <div className="d-flex justify-content-end align-items-center">
              <div className="d-flex align-items-center wallet text-light">
                {/* <Icon.Wallet /> : */}
                {profiles.credit}
              </div>
                <img src={headerwallet} alt="headerwallet" width="20px"/>

            </div>
              :""}
             
            
            </Col>
          </Row>
        </Container>
      </div>

    </>
  );
}
