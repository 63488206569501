import React,{useEffect,useRef} from 'react';
import { BrowserRouter, Route, Routes,useLocation  } from 'react-router-dom';
import './Style.scss';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Login from './Component/Login/Login';
import Home from './Component/Home';
import Wallet from './Component/Page/Wallet';
import Play from './Component/Page/Play';
import Jodi from './Component/Jodi_game/Jodi';
import Register from './Component/Login/Register';
import Changepassword from './Component/Page/Changepassword';
import ForgotPassword from './Component/Page/ForgotPassword';
import AddBank from './Component/Page/AddBank';
import WithdrawAmount from './Component/Page/WithdrawAmount';
import AddPayment from './Component/Page/AddPayment';
import MarketInner from './Component/Page/MarketInner';
import FAQ from './Component/Page/FAQ';
import Marcetret from './Component/Page/Marcetret';
import Playgame from './Component/Page/Playgame';
import Harruf from './Component/Jodi_game/Harruf';
import Profile from './Component/Page/Profile';
import Share from './Component/Page/Share';
import Resulthistory from './Component/Page/Resulthistory';
import ResulthistoryDelhi from './Component/Page/ResulthistoryDelhi';
import Notification from './Component/Page/Notification';
// import Jodidigitnew from './Component/Page/Jodidigitnew';
import Instruction from './Component/Page/Instruction';
// import Digit from './Component/Page/Digit';
import Howtouse from './Component/Page/Howtouse';
import '.././src/assets/fonts/font1/stylesheet.css';
import '.././src/assets/fonts/font-2/stylesheet.css';
import Header from ".././src/Component/Header/Header";
import Headercommon from ".././src/Component/Header/Headercommon";
import Footer from ".././src/Component/Footer";
import Jodi_Pana1 from './Component/Jodi_game/Jodi_Pana';
import Playgamedelhi from './Component/Jodi_game/Playgamedelhi';
import Crossing from './Component/Jodi_game/Crossing';
import Numbertonumber from './Component/Jodi_game/Numbertonumber';
import HarufbaseJodi from './Component/Jodi_game/HarufbaseJodi';
import PlayJantrl from './Component/Jodi_game/PlayJantrl';
import Play_Rashi_Jodi from './Component/Jodi_game/Play_Rashi_Jodi';


//------History page--------//

import Withdrawhistory from './Component/Historypage/Withdrawhistory';
import Wallethistory from './Component/Historypage/Wallethistory';
import Winighistory from './Component/Historypage/Winighistory';
import Addfoundhistory from './Component/Historypage/Addfoundhistory';
import Bithistory from './Component/Historypage/Bithistory';

//--------Kalyan Market-----------//

import Pana from './Component/Kalyanmarket/Pana';
import Gali from ".././src/Component/Kalyanmarket/Gali";
import Jodidigit from './Component/Kalyanmarket/Jodidigit';
import Singlapana from './Component/Kalyanmarket/Singlapana';
import Doublepana from './Component/Kalyanmarket/Doublepana';
import Troplepana from './Component/Kalyanmarket/Troplepana';
import Fullsangam from './Component/Kalyanmarket/Fullsangam';
import Halfsangam from './Component/Kalyanmarket/Halfsangam';



function App() {

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/*"
            element={
              <div>
                <Header />
                <Routes>
                  <Route path="/" element={<Play />} />
                  <Route path="/Share" element={<Share />} />
                  <Route path="/Howtouse" element={<Howtouse />} />
                  {/* <Route path="/PlayGameJodi" element={<PlayGameJodi />} /> */}
                  {/* <Route path="/Crossing" element={<Crossing />} /> */}
                  <Route path="/FAQ" element={<FAQ />} />
                  <Route path="/Changepassword" element={<Changepassword />} />
                  <Route path="/Notification" element={<Notification />} />
                  {/* <Route  path="/Gali" element={<Gali/>}/> */}
                </Routes>
                {/* <Footer/> */}
              </div>
            }
          />
          <Route path="/Login" element={<Login />} />
          <Route path="/Pana" element={<><Pana/><Headercommon /></>} />
          <Route path="/Playgame-jodi" element={<Jodi_Pana1 />} />
          <Route path="/Jodi-Game" element={<Playgamedelhi />} />
          <Route path="/Jodi" element={<><Jodi/><Headercommon /></>} />
          <Route path="/Crossing" element={<><Crossing/><Headercommon /></>} />
          <Route path="/Number-Number" element={<><Numbertonumber/><Headercommon /></>} />
          <Route path="/Haruf-Base-Jodi" element={<><HarufbaseJodi/><Headercommon /></>} />
          <Route path="/Play-Jantri" element={<><PlayJantrl/><Headercommon /></>} />
          <Route path="/Play-Rashi-Jodi" element={<><Play_Rashi_Jodi/><Headercommon /></>} />
          {/* <Route path="/Play-Jodi" element={<><PlayGameJodi /><Headercommon /></>} /> */}
          {/* <Route path="/Play-Harruf" element={<><PlayGameHarruf /><Headercommon /></>} /> */}
          <Route path="/Haruf" element={<><Harruf/><Headercommon /></>} />
          <Route path="/AddPayment" element={<><AddPayment /><Headercommon /></>} />
          <Route path="/Playgame" element={<><Playgame /><Headercommon /></>} />
          <Route path="/Register" element={<Register />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />} />
          <Route path="/Singlepana" element={<><Singlapana /><Headercommon /></>} />
          <Route path="/Gali" element={<><Gali /><Headercommon /></>} />
          <Route path="/Jodidigit" element={<><Jodidigit /><Headercommon /></>} />
          <Route path="/Doublepana" element={<><Doublepana /><Headercommon /></>} />
          <Route path="/Triplepana" element={<><Troplepana /><Headercommon /></>} />
          <Route path="/Halfsangam" element={<><Halfsangam /><Headercommon /></>} />
          {/* <Route path="/Jodidigitnew" element={<><Jodidigitnew /><Headercommon /></>} /> */}
          <Route path="/Fullsangam" element={<><Fullsangam /><Headercommon /></>} />
          <Route path="/Win-History" element={<><Winighistory /><Headercommon /></>} />
          <Route path="/Profile" element={<><Profile /><Headercommon /></>} />
          <Route path="/Bid-history" element={<><Bithistory /><Headercommon /></>} />
          <Route path="/Wallet" element={<><Wallet /><Headercommon /></>} />
          <Route path="/Resulthistory" element={<Resulthistory />} />
          <Route path="/ResulthistoryDelhi" element={<ResulthistoryDelhi />} />
          <Route path="/Instruction" element={<><Instruction /><Headercommon /></>} />
          <Route path="/Disawar-Market" element={<><MarketInner /><Headercommon /></>} />
          <Route path="/Market-rate" element={<><Marcetret /><Headercommon /></>} />
          {/* <Route path="/Jodi-Pana" element={<><Jodi_Pana /><Headercommon /></>} /> */}
          <Route path="/add-bank-detail" element={<><AddBank /><Headercommon /></>} />
          <Route path="/WithdrawAmount" element={<><WithdrawAmount /><Headercommon /></>} />
          <Route path="/Withdrawhistory" element={<><Withdrawhistory /><Headercommon /></>} />
          <Route path="/Add-fund-history" element={<><Addfoundhistory /><Headercommon /></>} />
          <Route path="/Wallet-history" element={<><Wallethistory /><Headercommon /></>} />



        </Routes>
      </BrowserRouter>


    </>
  );
}

export default App;
