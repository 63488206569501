import React, { useState, useEffect } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaPlay } from "react-icons/fa";
import { FileTextOutlined } from "@ant-design/icons";
import axios from "axios";
import * as Icon from 'react-bootstrap-icons';
import play from "../../assets/img/check-ch.png";
import { useNavigate } from "react-router-dom";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { MdOutlineStars } from "react-icons/md";
import playoff from "../../assets/img/playredbtn.png";
import playon from "../../assets/img/playon.png";
import chart from "../../assets/img/chart.jpg";
import { RiBarChartGroupedLine } from "react-icons/ri";
import { FaArrowRight } from "react-icons/fa";
const url = new URL(window.location.href);
const gameid = url.searchParams.get('id');
const url1 = new URL(window.location.href);
const name = url1.searchParams.get('name');


function MarketInner() {
  const [users, setUsers] = useState([]);
  const [usersdata, setUsersdata] = useState([]);
  const [loading1, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleReload = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    loaduser();
  }, []);

  const loaduser = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    // alert(dev_id)
    setLoading(true);

    // alert(user_id);
    let url = `${process.env.REACT_APP_API_URL}/Disawar_all_market.php`;
    // console.warn(url);
    const formData = new FormData();
    formData.append("app_id", process.env.REACT_APP_API_ID);
    formData.append("user_id", user_id);
    formData.append("dev_id", dev_id);
    // alert(dev_id)
    var config = {
      method: "POST",
      url: url,
      body: formData,
    };
    axios
      .post(url, formData, config)
      .then(function (response) {
        const res = JSON.stringify(response.data.data);
        const objectRes = JSON.parse(res);
        setUsers(objectRes);
        console.warn(objectRes);
      })
      .finally(() => {
        // setTimeout(() => {
        setLoading(false);
        // }, 10004);
      });
  };


  const [userss, setUserss] = useState("")


  const loaduserr = async () => {
    const user_id = localStorage.getItem('userid');
    const dev_id = localStorage.getItem('dev_id');
    let url = `${process.env.REACT_APP_API_URL}/get_user_profile.php`;
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    formData.append('dev_id', dev_id);
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config).then(function (response) {
      const res = JSON.stringify(response.data);
      const objectRes = JSON.parse(res);
      setUserss(objectRes);
    });
  };



  useEffect(() => {
    loaduserr();
  }, [])



  const handleClick = (id, name) => {
    localStorage.setItem('market_id',id);
    localStorage.setItem('market_name',name);
    // window.location.href = `/Jodi-Game`;
    navigate('/Jodi-Game');
  };

  const handleClick1 = async (id) => {
    navigate('/ResulthistoryDelhi');
    localStorage.setItem('marketchart_id', id,);

  };
  const handleClickclose = (id, name) => {
    if ("vibrate" in navigator) {
      // vibration API supported
      navigator.vibrate(50);
    }
  };
  return (
    <>
      <section id="play" className="margin-bottom-88">
        <div className="playsection  margin-top-15">
          <Container fluid>
            {/* <Row className="buttons gy-2 mt-5">
              <Col xs={6}>
                <div className="result_button btn">
                  <Link to="/Bit-history-Disawr">BID HISTORY</Link>
                </div>
              </Col>
              <Col xs={6}>
                <div className="add_fund btn">
                  <Link to="/Winig-history-Disawr">WIN HISTORY</Link>
                </div>
              </Col>
            </Row> */}

<div className="height_65">

<div className=" border-radius-8">
  <div className="playg marketinner">
    {users &&
      users.map((user) => (
        <>
          <div className="new">
            {user.is_play == 1 ? (
              <div className="d-flex align-items-start justify-content-between padding-5">
                <div className="div text-center w-20">
                  <div className="chart_image_new " onClick={() => {
                                handleClick1(user.id);
                              }}>
                    <img className="croseimg" src={chart} alt="" />
                  </div>
                  <span>&nbsp; </span>

                  {/* <span className="text-bold">Chart</span> */}
                </div>
                <div className="div  w-80">
                  <div className="marketname text-center">
                    <h4 className="animate-charcter flex-wrap marketnamenew mb-0 d-flex justify-content-center market_name text-center">
                      {user.name}
                    </h4>
                    <small className="p-0">
                    {user.result == null ? '**' : user.result}
                    </small>

                    {/* <p
                      onClick={() => {
                        handleReload();
                      }}
                      className="playgames p-0 "
                    >
                      Market opened{" "}
                    </p> */}

                    <div className="marketnamepaly">
                      <div className="date_time">
                        <p className="animate-charcter">
                          {/* <span className="openclose">Open:</span>{" "} */}
                          {user.open_time}
                        </p>
                        {/* ||
                        <p className="animate-charcter">
                          Closed: {user.Closetime}
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div onClick={() => { handleClick(user.id); }} className="div text-dark w-20">
      <span><img className="playimg" src={play} alt="" /></span>
      <p className="openn">Open</p>
    </div> */}
                <div
                  onClick={() => {
                    handleClick(user.id,user.name);
                  }}
                  className="div  text-center game_section_time w-20"
                >
                 <div className="game_off">
                    <img className="croseimg" src={playon} alt="" />
                  </div>
                  <p className="game_type">running </p>
                </div>
              </div>
            ) : (
              <div className="d-flex    align-items-start justify-content-between padding-5">
                <div className="div text-center w-20">
                  <div className="chart_image_new" onClick={() => {
                                handleClick1(user.id);
                              }}>
                    <img className="croseimg" src={chart} alt="" onClick={handleClickclose}/>
                  </div>
                  <span>&nbsp; </span>
                  {/* <span>Chart</span> */}
                </div>
                <div className="div  w-80">
                  <div className="marketname text-center">
                    <h4 className="animate-charcter flex-wrap marketnamenew mb-0 d-flex justify-content-center market_name text-center">
                      {user.name}
                    </h4>
                    <small className="p-0">
              {user.result == null ? '**' : user.result}
                    </small>

                    {/* <p
                      onClick={() => {
                        handleReload();
                      }}
                      className="playgames1 p-0 "
                    >
                      Market Closed{" "}
                    </p> */}
                    <div className="marketnamepaly ">
                      <div className="date_time ">
                        <p className="animate-charcter">
                        {user.open_time}
                        </p>
                        {/* ||
                        <p className="animate-charcter">
                          Close: {user.Closetime}
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="div game_section_time text-center w-20">
                  <div className="game_off">
                    <img
                      className="croseimg"
                      src={playoff}
                      onClick={handleClickclose}
                      alt=""
                    />
                  </div>
                  <p className="game_type close_color">closed </p>
                </div>
              </div>
            )}
          </div>
        </>
      ))}
  </div>
</div>
</div>
          </Container>
        </div>
        {loading1 && (
          
          <div className="spinner-wrapper">
            <div className="loadernew2"></div>
          </div>
        )}
      </section>
    </>
  )
}

export default MarketInner