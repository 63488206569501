import React, { useState,useEffect } from 'react';
import Logoimg from '../../assets/img/logo.png';
import { LockOutlined, UserOutlined, MobileOutlined } from '@ant-design/icons';
import { Button, Form, Input, Row, Col } from 'antd';
import axios from "axios";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';


export default function Register() {

  const [isShown, setIsShown] = useState("false")
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [mobile, setMobile] = useState('');
  const [otp, setOtp] = useState('');
  const [devid, SeTdevid] = useState('');
  const [useid, setUseid] = useState('');
  const [referrl, setreferrl] = useState('');


  
  // const [otpnumbers, otpnumber] = useState();

  
  const onFinish = (values) => {
    console.log('Received values of form: ', values);
  };
  var token =  localStorage.getItem("userid");
  // alert(token)
  const [browserId, setBrowserId] = useState('');

  
  useEffect(() => {

    let browserIdd = Cookies.get('browserId');
    //  alert(browserIdd)
    if (!browserIdd) {
      browserIdd = uuidv4();
      Cookies.set('browserId', browserIdd, { expires: 7 }); // Store the ID in a cookie that expires in 365 days
    }
    setBrowserId(browserIdd)


    // console.log('Browser ID:', browserIdd);
  }, []);

  // useEffect(() => {
  //  if(token){
  //   window.location.href = '/Home';
  //  }
  // }, [])

      // const dev_id = localStorage.getItem("dev_id");
  const onInputChangename = (e) => {
    setName(e.target.value);
  };
  const onInputReferrl = (e) => {
    setreferrl(e.target.value);
  };



  const onInputChangepassword = (e) => {
    setPassword(e.target.value);
  };

  const onInputChangemobile = (e) => {
    setMobile(e.target.value);
  };

  const onInputChangeotp = (e) => {
    setOtp(e.target.value);
  };

  // const navigate = useNavigate();

  const Registerotp = async () => {
    
    
    // setLoading(true);
    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('pss', password);
      formData.append('otp', otp);
      formData.append('mobileNum',mobile );
      formData.append('dev_id', browserId);
      formData.append('user_id', token);
      // formData.append('refercode', referrl);


      // formData.append('dev_id',devid );

      

      formData.append('app_id', process.env.REACT_APP_API_ID);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/register.php`, formData);
      console.warn(response.data.success);
      if (response.data.success ==  1) {
        setIsShown(true)
        SeTdevid(true)
        // setOtp(true)
        console.log()
        console.log(response.data);
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Login  successfully.',
         timer:2000
        }).then((result) => {
          window.location.href = '/';
          localStorage.setItem("userid", response.data.user_id);
          localStorage.setItem("dev_id", response.data.device_id);
        console.warn(response.data.dev_id);
          //  alert(response.data.dev_id)
        })
       
      }else if (response.data.success == 0) {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: response.data.message,
         timer:2000
        }).then((result) => {
          // window.location.href = '/play';
        })
      }
    
    } catch (error) {
      console.error('User Name Update:', error);
      console.error('An error occurred while updating username. Please try again later.');
      // setIsShown(false)

    }

  };
  const Sendotp = async () => {
    
    
    // setLoading(true);
    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('pss', password);
      formData.append('mobileNum',mobile );
      formData.append('dev_id',browserId );
      formData.append('user_id', token);


      formData.append('app_id', process.env.REACT_APP_API_ID);
      
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/registerstep1.php`, formData);
        
      console.warn(response.data.success);
      if (response.data.success == 1) {
        setIsShown(true)
        
        devid(true)
        // setOtp(true)
        console.log()
        console.log(response.data);
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Otp Send successfully.',
         timer:2000
        }).then((result) => {
          localStorage.setItem('dev_id', response.data.device_id);
          // window.location.href = '/';
        })
       
      }else if (response.data.success == 0) {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: response.data.message,
         timer:2000
        }).then((result) => {
          // window.location.href = '/Home';
        })
      }
    
    } catch (error) {
      console.error('User Name Update:', error);
      console.error('An error occurred while updating username. Please try again later.');
      // setIsShown(false)

    }

  };





  return (
    <>
      <section id="Register">
        <div className="register">
          <Row justify={'center'}>
            <Col xs={24} xl={8}>
              <div className="logo w-100 text-center mt-3">
                <img src={Logoimg} className="img-fluid" alt="Logo" style={{width:"150px"}} />
              </div>

              <div className="formregister">
                <Form
                  name="normal_login"
                  className=""
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                >
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Name!',
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Name"
                      value={name}
                      onChange={onInputChangename}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Password!',
                      },
                    ]}
                  >
                    <Input
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={onInputChangepassword}
                    />
                  </Form.Item>
                  <Form.Item
                    name="Mobile"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Mobile number!',
                      },
                    ]}
                  >
                    <Input
                      prefix={<MobileOutlined className="site-form-item-icon" />}
                      type="text"
                      placeholder="Mobile Number"
                      value={mobile}
                      onChange={onInputChangemobile}
                    />
                  </Form.Item>
                  {isShown == true ? (
                    <Col span={24} xl={24} md={24}>
                      <Form.Item
                        name="otp"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your password!',
                          },
                        ]}
                      >

                        <Input style={{ padding: "9px" }} onChange={onInputChangeotp} placeholder="Enter OTP" />

                      </Form.Item>
                    </Col>
                  ) : null}


                    {/* <Form.Item
                    name="namee"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Name!',
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Referrl"
                      value={referrl}
                      onChange={onInputReferrl}
                    />
                  </Form.Item> */}
                
                  <Form.Item>
                  {isShown == true ? <button type="button" onClick={Registerotp} className="bg-green w-100 btn refer-button cxy  otp_login submit_data" id="submit_data" name="submit_data" >Register</button> : <button type="submit" className="w-100 btn  text-white bg-green refer-button cxy mt-4 send-otp" id="send_ottp"  onClick={Sendotp}>Send OTP</button>}


                    </Form.Item>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}
