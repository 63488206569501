import React, { useState, useEffect } from 'react';
import rup from "../../assets/img/rup.jpg";

import axios from "axios";
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { Card, Col, Row, Form, Spinner } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';

function Winighistory() {

  const [users, setUserss] = useState("")
  const [user, setUsers] = useState("")
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  console.warn(users)
  const [selectedMarket, setSelectedMarket] = useState('1');

  const handleMarketChange = (event) => {
    setSelectedMarket(event.target.value);
  };

  useEffect(() => {
    kalyanGame();
    mainMarket();
  }, [])
  const kalyanGame = async () => {
    setLoading1(true);
    const user_id = localStorage.getItem('userid');
    const dev_id = localStorage.getItem('dev_id');
    let url = `${process.env.REACT_APP_API_URL}/Bit_win_history.php`;
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    formData.append('dev_id', dev_id);
    formData.append('type', 'kalyan');
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config).then(function (response) {
      const res = JSON.stringify(response.data.data);
      if (res != undefined) {
        const objectRes = JSON.parse(res);
        setUserss(objectRes);
        console.warn(objectRes);
      }
    })
    .finally(() => {
      // setTimeout(() => {
      setLoading1(false);
      // }, 10004);
    });
  };
  const mainMarket = async () => {
    setLoading(true);
    const user_id = localStorage.getItem('userid');
    const dev_id = localStorage.getItem('dev_id');
    let url = `${process.env.REACT_APP_API_URL}/Bit_win_history.php`;
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    formData.append('dev_id', dev_id);
    formData.append('type', 'mainMarket');
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config).then(function (response) {
      const res = JSON.stringify(response.data.data);
      if (res != undefined) {
        const objectRes = JSON.parse(res);
        setUsers(objectRes);
        console.warn(objectRes);
      }
    })
      .finally(() => {
        // setTimeout(() => {
        setLoading(false);
        // }, 10004);
      });
  };

  return (
    <>
      <div id='bithistory' className='mainhome'>
        <Form.Select aria-label="Default select example" className='mt-5' onChange={handleMarketChange} value={selectedMarket}>
          <option value="1">Delhi Market</option>
          <option value="2">Kalyan Market</option>
        </Form.Select>

        {selectedMarket === '1' && (
          <>
            {user && user.length > 0 ? (
              user && user.map((user, index) => (
                <Card className="mt-2 border-color">

                  <Card.Header
                    className="headd bg-dark text-light d-flex justify-content-center"
                    as="h6"
                  >
                    {user.market_name}({user.gametypeName},{user.status})
                  </Card.Header>
                  <Card.Body className='padding-top-3'>
                    <Row className="winn">
                      <Col md={4} sm={4} xs={4} className='text-center'>
                        <p className='mb-0 text-black'>Bid ID</p>
                        <span className="colorprednumm">{user.date}</span>

                      </Col>
                      <Col md={4} sm={4} xs={4} className='text-center'>
                        <p className='mb-0 text-black'>{user.market_name}</p>
                        <span className="colorprednumm"> {user.pred_num}</span>
                      </Col>
                      <Col md={4} sm={4} xs={4} className='text-center'>
                        <p className='mb-0 text-black'>Point</p>
                        <span className="colorprednumm  text-success"> {user.tr_value}</span>
                      </Col>
                    </Row>
                    {/* <p className=" timingg text-center text-black">Bid Time: {user.betTime}</p> */}
                  </Card.Body>
                </Card>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-dark">No data available or something went wrong.</td>
              </tr>
            )}
          </>
        )}

        {selectedMarket === '2' && (
          <>
            {users && users.length > 0 ? (
              users && users.map((user, index) => (
                <>
                  <Card className="mt-2 border-color" >

                    <Card.Header
                      className="headd bg-dark text-light d-flex justify-content-center"
                      as="h6"
                    >
                      {user.market_name}({user.gametypeName},{user.status})
                    </Card.Header>
                    <Card.Body className='padding-top-3'>
                      <Row className="winn">
                        <Col md={4} sm={4} xs={4} className='text-center'>
                          <p className='mb-0 text-black'>Bid ID</p>
                          <span className="colorprednumm">{user.date}</span>

                        </Col>
                        <Col md={4} sm={4} xs={4} className='text-center'>
                          <p className='mb-0 text-black'>{user.market_name}</p>
                          <span className="colorprednumm"> {user.pred_num}</span>
                        </Col>
                        <Col md={4} sm={4} xs={4} className='text-center'>
                          <p className='mb-0 text-black' >Point</p>
                          <span className="colorprednumm text-success"> {user.tr_value}</span>
                        </Col>
                      </Row>
                      {/* <p className=" timingg text-center text-black">Bid Time: {user.betTime}</p> */}
                    </Card.Body>
                  </Card>
                </>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-dark">No data available or something went wrong.</td>
              </tr>
            )}
          </>
        )}



      </div>
      {loading && (

        <div className="spinner-wrapper">
          <div className="loadernew2"></div>
        </div>
      )}
      {loading1 && (

        <div className="spinner-wrapper">
          <div className="loadernew2"></div>
        </div>
      )}
    </>
  )
}

export default Winighistory